import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'It\'s the Only NEET Thing to Do.'
    }
  },
  {
    path: '/video',
    name: 'Video',
    meta: {
      title: '视频'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "video" */ '../views/Video.vue')
  },
  {
    path: '/mhr',
    name: 'Mhr',
    meta: {
      title: 'MHR记录'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "mhr" */ '../views/Mhr.vue')
  },
  {
    path: '/kancolle/suffering',
    name: 'KancolleSuffering',
    meta: {
      title: 'Kancolle - 被害计算器'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kancollesuffering" */ '../views/KancolleSuffering.vue')
  },
  {
    path: '/kancolle/replay',
    name: 'KancolleReplay',
    meta: {
      title: 'Kancolle - 回放'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kancollereplay" */ '../views/KancolleReplay.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
