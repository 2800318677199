<template>
  <el-row>
    <el-col :span="18">
      <div style="text-align: center;">
      <img src='~@/assets/img/home.jpg' width="1000" align="middle"/>
      <h1>It's the Only NEET Thing to Do.</h1>
      </div>
    </el-col>
  </el-row>
</template>

<style scoped>
h1 {
  font-family: "Microsoft YaHei Light","Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
