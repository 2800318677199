<template>
  <el-container>
  <el-header>
    <el-menu router :default-active="$route.path" mode="horizontal">
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/video">视频</el-menu-item>
      <el-menu-item index="/mhr">MHR记录</el-menu-item>
      <el-submenu index="/kancolle">
          <template #title>Kancolle</template>
          <el-menu-item index="/kancolle/replay">回放</el-menu-item>
          <el-menu-item index="/kancolle/suffering">被害计算器</el-menu-item>
      </el-submenu>
    </el-menu>
  </el-header>
  <el-main>
  <router-view/>
  </el-main>
  </el-container>
</template>

<style lang="scss">
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
